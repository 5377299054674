import { gql } from "graphql-request";

export const ACADEMY_NEWS_INSERT = gql`
    mutation insertAcademyNews($title: String!, $title_ar: String, $content: String, $content_ar: String, $img: Upload, $valid_thru: String){
        insertAcademyNews(insertNewsInput: {title: $title, title_ar: $title_ar, content: $content, content_ar: $content_ar, img: $img, valid_thru: $valid_thru}){
        status,
        msg,
        path,
        id
        }
    }
`
export const ACADEMY_NEWS_UPDATE = gql`
    mutation AcademyNewsUpdate($id: Int,$title: String!, $title_ar: String, $content: String, $content_ar: String, $img: Upload, $valid_thru: String){
        updateAcademyNews(updateNewsInput: {id: $id,title: $title, title_ar: $title_ar, content: $content, content_ar: $content_ar, img: $img, valid_thru: $valid_thru}){
        id
        msg
        status
        }
    }
`

export const ACADEMY_NEWS_DELETE = gql`
    mutation deleteAcademyNews($id: Int!) {
        deleteAcademyNews(deleteNewsInput: {id: $id}) {
            status
        }
    }
`
