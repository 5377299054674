<template>
  <div class="mt-3" v-show="formIsOpen">
    <va-inner-loading :loading="isLoading">
      <form @submit.prevent="formSubmit">
        <div class="md6">
          <va-input
            class="mb-4 mx-3"
            v-model="data.title"
            :label="$t('title')"
            required
          />

          <va-input
            class="mb-4 mx-3"
            v-model="data.title_ar"
            :label="$t('title_ar')"
            required
          />
        </div>
        <div class="mb-4 mx-3">
          <v-quill
            id="content"
            toolbar="full"
            content-type = "html"
            v-model:content="data.content"
            required
            
            :label="$t('content')"
          />
          
        </div>
        <div class="mb-4 mx-3">
          <v-quill
            id="content_ar"
            toolbar="full"
            content-type = "html"
            v-model:content="data.content_ar"
            required
            :label="$t('content_ar')"
          />
        </div>
        <div class="col">
          <va-file-upload
            class="mb-4 mx-3"
            type="gallery"
            v-model="data.img"
            required
            id="img"
            :label="$t('subscription_fees')"
          />
          <va-date-input
            class="mb-4 mx-3"
            v-model="data.valid_thru"
            required
            :label="$t('valid_thru')"
          />
        </div>

        <va-divider inset />

        <div class="card_footer">
          <va-button outline class="mr-4" type="submit">{{
            $t("save")
          }}</va-button>
          <va-button flat color="danger" class="mr-4" @click="closeForm">{{
            $t("cancel")
          }}</va-button>
        </div>
      </form>
    </va-inner-loading>
  </div>
</template>

<script>
import { GraphQLClient } from "graphql-request";
import BlotFormatter from 'quill-blot-formatter'
import { ACADEMY_NEWS_INSERT } from "@/graphql/mutations/academy/news";
import { ACADEMY_NEWS_UPDATE } from "@/graphql/mutations/academy/news";
import {  ref } from "vue";

export default {
  props: ["formIsOpen", "selectedRowToEdit"],
  data() {
    return {
      data: {},
      isLoading: false,
      modules: {
        name: "blotFormatter",
        module: BlotFormatter,
      },
    };
  },
  mounted(){
    console.log(this.data);
  },
  emits: ["closeForm", "rowInserted", "rowUpdated"],
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    async formSubmit() {
      this.isLoading = true;

      const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          // authorization: 'Bearer MY_TOKEN',
        },
      });

      const variables = {
        "title": this.data.title,
        "title_ar": this.data.title_ar,
        "content": this.data.content,
        "content_ar": this.data.content_ar,
        "valid_thru": this.data.valid_thru.toISOString(),
        "img": this.data.img?.[0],
      };

      let query = "";

      if (!this.data.id) {
        // Add Section
        console.log("insert");
        query = ACADEMY_NEWS_INSERT;
      } else {
        //edit Section
        query = ACADEMY_NEWS_UPDATE;
        variables.id = this.data.id;
      }
      try {
        const data = await graphQLClient.request(query, variables);
        this.isLoading = false;
        console.log(JSON.stringify(data, undefined, 2));
        this.data = {};
        // console.log(this.selectedRowToEdit)
        // if(this.selectedRowToEdit){
        this.$emit("rowUpdated", data);
        //     console.log(data.insertNews);
        // }else{
        //     this.$emit('rowInserted', data.insertNews)
        // }
        // this.closeForm();
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
  },
  watch: {
    selectedRowToEdit(n) {
      if (this.selectedRowToEdit != {}) {
        this.data.id = this.selectedRowToEdit.id;
        this.data.title = this.selectedRowToEdit.title ? this.selectedRowToEdit.title : '';
        this.data.title_ar = this.selectedRowToEdit.title_ar ? this.selectedRowToEdit.title_ar: '';
        this.data.valid_thru = new Date(this.selectedRowToEdit.valid_thru)
        document.querySelector("#content .ql-editor").innerHTML = this.selectedRowToEdit.content? ref(this.selectedRowToEdit.content).value : ''
        document.querySelector("#content_ar .ql-editor").innerHTML = this.selectedRowToEdit.content_ar? ref(this.selectedRowToEdit.content_ar).value : ''
        
      }
    },
  },
};
</script>

<style>
.md6 {
  display: flex;
}
</style>
