import { gql } from "graphql-request";

export const NEWS_LIST = gql`
  query {
    AcademyNews{
      title,
      title_ar,
      content,
      content_ar,
      valid_thru,
      id,
      img
    }
  }
`
