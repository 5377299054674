<template>
  <va-card>
    <va-card-title>{{ $t("academy_news") }}</va-card-title>

    <va-card-content>
      <div style="display: flex">
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('menu.main')" />
          <va-breadcrumbs-item :label="$t('menu.academy')" />
          <va-breadcrumbs-item :label="$t('menu.news')" />
        </va-breadcrumbs>
        <div class="md6">
          <va-button
            color="info"
            @click="formIsOpen = !formIsOpen"
            class="mr-4"
          >
            <span class="material-icons-outlined"> add </span>
            {{ $t("add") }}
          </va-button>
        </div>
      </div>

      <news-form
        :selectedRowToEdit="selectedRowToEdit"
        :formIsOpen="formIsOpen"
        @closeForm="closeForm"
        @rowUpdated="upateRow"
      ></news-form>
    </va-card-content>
  </va-card>

  <div class="mt-3">
    <div class="flex">
      <va-card stripe stripe-color="primary">
        <va-card-content>
          <div class="">
            <div class="va-table-responsive">
              <va-inner-loading :loading="isLoading">
                <table class="va-table va-table--hoverable">
                  <thead>
                    <tr>
                      <th>{{ $t("title") }}</th>
                      <th>{{ $t("title_ar") }}</th>
                      <th>{{ $t("content") }}</th>
                      <th>{{ $t("content_ar") }}</th>
                      <th>{{ $t("valid_thru") }}</th>
                      <th>{{ $t("img") }}</th>
                      <th>{{ $t("actions") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(service, i) in services" :key="service.id">
                      <td>{{ service.title }}</td>
                      <td>{{ service.title_ar }}</td>
                      <td v-html="service.content.slice(0,70)"></td>
                      <td v-html="service.content_ar.slice(0,70)"></td>
                      <td>
                        {{ service.valid_thru ? $d(service.valid_thru) : "-" }}
                      </td>
                      <!-- <td>{{service.img}}</td> -->
                      <img width="50" height="50" :src="this.$store.state.imgUrl + service.img" />
                      <td>
                        <div
                          style="display: flex; justify-content: space-evenly"
                        >
                          <va-button
                            color="info"
                            @click="editRow(service)"
                            icon="edit"
                            flat
                          />
                          <va-button
                            @click="deleteRow(service.id, i)"
                            color="danger"
                            icon="trash"
                            flat
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </va-inner-loading>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import { request, GraphQLClient } from "graphql-request";
import NewsForm from "@/components/forms/academy/NewsForm.vue";
import { NEWS_LIST } from "@/graphql/queries/academy/news";
import { ACADEMY_NEWS_DELETE } from "@/graphql/mutations/academy/news";
export default {
  components: {
    NewsForm,
  },
  data() {
    return {
      services: [],
      formIsOpen: false,
      selectedRowToEdit: {},
      isLoading: false,
    };
  },
  methods: {
    // insertTableRow(row){
    //   this.services.push(row);
    //   this.formIsOpen = false;
    // },
    getNews() {
      this.isLoading = true;
      request(this.$store.state.appUrl, NEWS_LIST)
        .then((data) => {
          this.isLoading = false;
          this.services = data.AcademyNews;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$swal.fire({
            text: this.$t("error_msg"),
            icon: "error",
            confirmButtonColor: "#039303",
            confirmButtonText: this.$t("ok"),
          });
        });
    },
    closeForm() {
      this.formIsOpen = false;
      this.selectedRowToEdit = {};
    },
    upateRow(row) {
      this.getNews();
      this.selectedRowToEdit = {};
      this.formIsOpen = false;
    },
    editRow(service) {
      this.selectedRowToEdit = { ...service };
      this.formIsOpen = true;
    },
    deleteRow(id, i) {
      this.$swal
        .fire({
          title: `${this.$t("sure?")}`,
          text: this.$t("delete_text"),
          icon: "question",
          showCancelButton: true,
          
          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            const graphQLClient = new GraphQLClient(this.$store.state.appUrl);

            const variables = {
              id,
            };
            try {
              graphQLClient
                .request(ACADEMY_NEWS_DELETE, variables)
                .then((data) => {
                  console.log(JSON.stringify(data, undefined, 2));

                  this.getNews();
                });
            } catch (err) {
              console.log(err);
            }
          }
        });
    },
  },
  created() {
    this.getNews();
  },
};
</script>

<style></style>
